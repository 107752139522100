
import { useEffect, useState, useRef } from 'react'
import './image-slider.scss'

import banner_img01 from '../../images/banner/banner01.jpeg'
import banner_img02 from '../../images/banner/banner02.jpeg'
import banner_img03 from '../../images/banner/banner03.jpeg'

const slider_images = [banner_img01, banner_img02, banner_img03];
const delay = 2500;

function ImageSlider(props) {
    const [index, setIndex] = useState(0);
    const timeoutRef = useRef(null);

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(() => setIndex((prevIndex) =>
            prevIndex === slider_images.length - 1 ? 0 : prevIndex + 1
        ), delay);
        return () => {
            resetTimeout();
        };
    }, [index]);

    return (
        <div className="image-slider">
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 col-sm-12' style={{}}>

                        <div className="slideshow">
                            <div className="slideshowSlider"
                                style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}>
                                {slider_images.map((backgroundColor, index) => (
                                    <img className="slide" key={index} src={backgroundColor} alt="" onClick={() => { setIndex(index === slider_images?.length-1 ? 0 : index+1); }}/>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    );
}

export default ImageSlider;
