import ImageSlider from '../image-slider/image-slider';
import './banner.scss'

function Banner() {
  return (
    <div className="banner">
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 col-sm-12' style={{paddingLeft: '0px', paddingRight: '0px'}}>
            <p className='name-text'>Welcome to Elysian Creatve Designs</p>
            <ImageSlider ImageData={ImageData} SlideInterValTime={ 2000}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
