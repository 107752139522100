import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../logo.jpg'
import { UserContext } from '../../context/UserContect';

function Header() {
  const ref = useRef();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    }
  },[]);

  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      setExpanded(false);
    }
  };

  return (
    <UserContext.Consumer>
      {({ cart }) => (
      <div className="header" ref={ref}>
        <nav className="navbar navbar-expand-xl navbar-light bg-light fixed-top" expand="xl"
				expanded={expanded.toString()}>
          <div className="container-fluid">
            <Link to="/" className="navbar-brand"onClick={() => {
              setExpanded(false);
            }}>
              <img src={logo} alt="logo" height="40" />
            </Link>
            <div className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => {
              setExpanded(!expanded);
            }}>
              <span className="navbar-toggler-icon"></span>
            </div>
          </div>
          <div className="container-fluid">
            <div className={`collapse navbar-collapse ${expanded ? 'show' : ''}`} onClick={() => {
              setExpanded(false);
            }}>
              <div className='m-auto'>
                <ul className="navbar-nav me-auto mb-2 mb-xl-0">
                  <li className="nav-item">
                    <Link to="/collections" className="nav-link" aria-current="page">
                      Collections
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/about-us" className="nav-link" aria-current="page">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/shipping-and-returns" className="nav-link" aria-current="page">
                      Shipping & Returns
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/contact-us" className="nav-link" aria-current="page">
                      Contact Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/cart" className="nav-link" aria-current="page">
                    <i className="fa fa-shopping-cart" aria-hidden="true" style={{position: 'relative', fontSize: '24px'}}>
                    {cart?.length > 0 && <div style={{position: 'absolute', backgroundColor: '#FFB6C1', padding: '2px', paddingLeft: '5px', paddingRight: '5px', borderRadius: '10px', fontSize: '14px', top: '10px', right: '-8px', color: '#ffffff'}}>{cart?.length}</div>}
                    </i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
      )}
    </UserContext.Consumer>
  );
}

export default Header;
