import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './context/UserContect';
import Header from './components/header/Header';
import MyRoutes from './routes/MyRoutes';
import Footer from './components/footer/Footer';
import './App.scss';

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <UserProvider>
          <Header />
          <MyRoutes />
          <Footer />
        </UserProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
