import { useEffect, useState } from "react";
import GridView from "../components/grid-view/GridView";
import CollectionService from "../services/CollectionService";
import Loader from "../components/loader/Loader";

function CollectionsPage() {
    const service = new CollectionService();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
        return (() => {
            setData();
        })
        // eslint-disable-next-line
    }, [])

    const getData = async () => {
        const response = await service.getCategories();
        setData(response?.data);
        setLoading(false);
    }

    return (
        <div className="page all-items-page">
            <Loader loading={loading} />
            {data?.length > 0 && <GridView data={data} preroute={'/products'} type={'category'}/>}
            <div className="divider"></div>
        </div>
    );
}

export default CollectionsPage;
