import { useEffect } from "react";
import Banner from "../components/banner/banner";
import CollectionsPage from "./CollectionsPage";

function HomePage() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page home-page text-secondary">
      <Banner />
      <div className="remove-padding-top">
        <CollectionsPage/>
      </div>
    </div>
  );
}

export default HomePage;
