import { useEffect } from 'react';
import BabyGrowIcon from '../images/baby-grow-icon.png';

function NotFoundPage() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="page not-found-page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h2>Not Found Page</h2>
                        <img src={BabyGrowIcon} alt="icon" style={{ maxHeight: '50px', rotate: '0deg', marginBottom: '20px' }} />
                    </div>
                </div>
            </div>

        </div>
    );
}

export default NotFoundPage;
