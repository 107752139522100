import { useEffect } from 'react';
import ContactForm from '../components/contact-form/ContactForm';
import BabyGrowIcon from '../images/baby-grow-icon.png';

function ContactUsPage() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page contact-us-page">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h2>Contact Elysian Creative Designs</h2>
            <img src={BabyGrowIcon} alt="icon" style={{ maxHeight: '50px', rotate: '0deg', marginBottom: '20px' }} />
          </div>
          <div className="col-0 col-sm-3"></div>
          <div className="col-12 col-sm-6">
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
          </div>
          <div className="col-12">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUsPage;
