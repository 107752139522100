import { useState, useContext, useEffect, Fragment } from 'react';
import { UserContext } from '../../context/UserContect';
import { Link } from 'react-router-dom';
import './ItemView.scss'

function ItemView({ data }) {
    const userContext = useContext(UserContext);

    const [model, setModel] = useState({
        id: "",
        name: "",
        price: 0,
        size: "",
        color: "",
        style: "",
        option: "",
        print: "",
        quantity: 1,
        imageurl: ""
    })

    useEffect(() => {
        data && setModel({
            id: data?.id,
            name: data?.name,
            price: data?.price,
            size: data?.attributes?.find((attr) => attr?.name === "Size")?.options?.length > 0 ? data?.attributes?.find((attr) => attr?.name === "Size")?.options[0] : "",
            color: data?.attributes?.find((attr) => attr?.name === "Color")?.options?.length > 0 ? data?.attributes?.find((attr) => attr?.name === "Color")?.options[0] : "",
            style: data?.attributes?.find((attr) => attr?.name === "Style")?.options?.length > 0 ? data?.attributes?.find((attr) => attr?.name === "Style")?.options[0] : "",
            option: data?.attributes?.find((attr) => attr?.name === "Option")?.options?.length > 0 ? data?.attributes?.find((attr) => attr?.name === "Option")?.options[0] : "",
            print: data?.attributes?.find((attr) => attr?.name === "Print")?.options?.length > 0 ? data?.attributes?.find((attr) => attr?.name === "Print")?.options[0] : "",
            quantity: 1,
            imageurl: data?.images?.length > 0 ? data?.images[0]?.src : ""
        });
        return () => {
            setModel({
                id: "",
                name: "",
                price: 0,
                size: "",
                color: "",
                style: "",
                option: "",
                print: "",
                quantity: 1,
                imageurl: ""
            });
        };
    }, [data]);

    return (
        <div className="item-view">
            <div className="container">
                <div className="row" style={{ paddingTop: '10px' }}>
                    <div className="col-12 col-md-7" style={{ padding: '5px' }}>
                        {data?.images?.length > 0 &&
                            <img src={`${data?.images[0]?.src}`} className="card-img-top" alt={data?.images[0]?.name} />
                        }
                    </div>
                    <div className="col-12 col-md-5 text-left" style={{ padding: '5px', paddingLeft: '45px' }}>
                        <div>
                            <Link to={`/collections`} className="btn add-to-cart-btn" style={{/*float: 'right', */marginBottom: '10px' }}>
                                Continue Shopping
                            </Link>
                        </div>
                        <p className='item-name'>{data?.name}</p>
                        <p className='item-price'>R {parseFloat(data?.price)?.toFixed(2)} ZAR</p>
                        <p className='item-label' style={{ marginTop: '0px' }}>Tax included.</p>
                        {data?.attributes?.length > 0 &&
                            <Fragment>
                                {data?.attributes?.find((attr) => attr?.name === "Size")?.options?.length > 0 &&
                                    <Fragment>
                                        <p className='item-label'>Size</p>
                                        {data?.attributes?.find((attr) => attr?.name === "Size")?.options?.map((item, index) => {
                                            return <div key={index} className={`select-chip ${item === model?.size && 'selected'}`} onClick={() => setModel({ ...model, size: item })}>{item}</div>
                                        })}
                                    </Fragment>
                                }
                                {data?.attributes?.find((attr) => attr?.name === "Color")?.options?.length > 0 &&
                                    <Fragment>
                                        <p className='item-label'>Color</p>
                                        {data?.attributes?.find((attr) => attr?.name === "Color")?.options?.map((item, index) => {
                                            return <div key={index} className={`select-chip ${item === model?.color && 'selected'}`} onClick={() => setModel({ ...model, color: item })}>{item}</div>
                                        })}
                                    </Fragment>
                                }
                                {data?.attributes?.find((attr) => attr?.name === "Style")?.options?.length > 0 &&
                                    <Fragment>
                                        <p className='item-label'>Style</p>
                                        {data?.attributes?.find((attr) => attr?.name === "Style")?.options?.map((item, index) => {
                                            return <div key={index} className={`select-chip ${item === model?.style && 'selected'}`} onClick={() => setModel({ ...model, style: item })}>{item}</div>
                                        })}
                                    </Fragment>
                                }
                                {data?.attributes?.find((attr) => attr?.name === "Option")?.options?.length > 0 &&
                                    <Fragment>
                                        <p className='item-label'>Option</p>
                                        {data?.attributes?.find((attr) => attr?.name === "Option")?.options?.map((item, index) => {
                                            return <div key={index} className={`select-chip ${item === model?.option && 'selected'}`} onClick={() => setModel({ ...model, option: item })}>{item}</div>
                                        })}
                                    </Fragment>
                                }
                                {data?.attributes?.find((attr) => attr?.name === "Print")?.options?.length > 0 &&
                                    <Fragment>
                                        <p className='item-label'>Print</p>
                                        {data?.attributes?.find((attr) => attr?.name === "Print")?.options?.map((item, index) => {
                                            return <div key={index} className={`select-chip ${item === model?.print && 'selected'}`} onClick={() => setModel({ ...model, print: item })}>{item}</div>
                                        })}
                                    </Fragment>
                                }
                            </Fragment>
                        }
                        <p className='item-label'>Quantity</p>
                        <div style={{ cursor: 'pointer', paddingBottom: '20px' }}>
                            <div style={{ display: 'inline-flex', borderTop: '1px solid', borderBottom: '1px solid', padding: '5px 15px 5px', borderLeft: '1px solid', userSelect: 'none' }} onClick={() => setModel({ ...model, quantity: model?.quantity > 1 ? model.quantity - 1 : model.quantity })}>-</div>
                            <div style={{ display: 'inline-flex', borderTop: '1px solid', borderBottom: '1px solid', padding: '5px 15px 5px', userSelect: 'none', minWidth: '43.78px', fontWeight: '400' }}>{model?.quantity}</div>
                            <div style={{ display: 'inline-flex', borderTop: '1px solid', borderBottom: '1px solid', padding: '5px 15px 5px', borderRight: '1px solid', userSelect: 'none' }} onClick={() => setModel({ ...model, quantity: model?.quantity < 10 ? model.quantity + 1 : model.quantity })}>+</div>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: data?.description }} ></div>
                        <Link to="/cart">
                            <div className="btn add-to-cart-btn" onClick={() => { userContext?.addToCart(model) }}>
                                Add to Cart
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ItemView;
