import React from 'react';
import ReactDOM from 'react-dom/client';
import 'font-awesome/css/font-awesome.min.css';
import "bootstrap/dist/js/bootstrap.min.js";
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <App />
);