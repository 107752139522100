
import React, { useState } from 'react';

const UserContext = React.createContext({ cart: [], addToCart: null, updateCart: null }); // Create a context object

const UserProvider = ({ children }) => {
    const [cart, setCart] = useState([]);

    const addToCart = (e) => {
        const tmp = Object.assign([],cart);
        tmp.push(e);
        setCart(tmp);
    }

    const updateCart = (e) => {
        setCart(e);
    }

    const getProvider = () => {
        return {
            cart: cart,
            addToCart: addToCart,
            updateCart: updateCart
        }
    }

    return (
        <UserContext.Provider value={getProvider()}>
            {children}
        </UserContext.Provider>
    );
}

export {
    UserContext,
    UserProvider
};
