class SendMailService {
    sendContactMail = async (name, from, number, message) => {
        try {
            const response = await window.Email.send({
                SecureToken: "8aaeb561-506b-414d-8e47-f111daeb3159",
                To: ["order@elysiancreativedesigns.co.za"],
                From: "order@elysiancreativedesigns.co.za",
                Subject: "Contact Us - Elysian Creative Designs",
                Body: "<h2>Contact Us</h2><br><strong>Name:</strong> " + name + "<br><strong>Email: </strong>" + from + "<br><strong>Moblie:</strong> " + number + "<br><br>" + message
            });
            return response === "OK" ? true : false;
        } catch (e) {
            return false;
        }
    }

    sendOrderMail = async (name, from, number, message, cart, images) => {
        let img = [];
        if (images.length > 0) {
            for (let i = 0; i < images.length; i++) {
                //console.log(images[i]?.name);
                //console.log((images[i]?.size / 1048576).toFixed(1));
                let tmp = {
                    name: images[i].name,
                    data: await this.convertBase64(images[i])
                };
                //tmp.name = images[i].name,
                //tmp.data = await this.convertBase64(images[i])
                img.push(tmp);
            }
        }
        try {
            let c = this.generateCartMessage(cart);
            const response = await window.Email.send({
                SecureToken: "8aaeb561-506b-414d-8e47-f111daeb3159",
                To: ["order@elysiancreativedesigns.co.za"],
                From: "order@elysiancreativedesigns.co.za",
                Subject: "Order - Elysian Creative Designs",
                Body: "<h2>Order</h2><br><strong>Name:</strong> " + name + "<br><strong>Email: </strong>" + from + "<br><strong>Moblie:</strong> " + number + "<br><br>" + c  + "<br>" + message,
                Attachments : img
            });
            return response === "OK" ? true : false;
        } catch (e) {
            return false;
        }
    }

    generateCartMessage(cart) {
        let total = 0.0;
        let table = `<table>`
        table += `<tr>
            <td>
                <strong>ID</strong>
            </td>
            <td>
                <strong>Name</strong>
            </td>
            <td>
                <strong>Price</strong>
            </td>
            <td>
                <strong>Quantity</strong>
            </td>
            <td>
            <strong>Total</strong>
            </td>
            <td>
                <strong>Tags</strong>
            </td>
            <td>
                <strong>Images</strong>
            </td>
        </tr>`;
        table += cart.map(item => {
            total = (parseFloat(total)+(parseFloat(item?.price)*parseFloat(item?.quantity)));
            return `<tr>
                <td>
                    ${item?.id}
                </td>
                <td>
                    ${item?.name}
                </td>
                <td>
                    R ${item?.price}
                </td>
                <td>
                    ${item?.quantity}
                </td>
                <td>
                    R ${(parseFloat(item?.price)*parseFloat(item?.quantity)).toFixed(2)}
                </td>
                <td>
                    ${item?.color ? item?.color + ";" : ""}
                    ${item?.size ? item?.size + ";" : ""}
                    ${item?.option ? item?.option + ";" : ""}
                    ${item?.style ? item?.style + ";" : ""}
                    ${item?.print ? item?.print + ";" : ""}
                </td>
                <td>
                    <img src="${item?.imageurl}" alt="${item?.imageurl}" height="100"/>
                </td>
            </tr>`
        })
        table += `</table>`;
        table += `<div>
            <br><strong>Total: </strong>R ${parseFloat(total).toFixed(2)}
        </div>`;
        return table;
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
    }
}

export default SendMailService;