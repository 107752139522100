import { Link } from 'react-router-dom';
import { UserContext } from '../../context/UserContect';

function Footer() {
  return (
    <UserContext.Consumer>
      {({ cart }) => (
        <div className="footer bg-light text-secondary">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h2>
                  Quick Links
                </h2>
              </div>
              <div className="col-6 text-right" style={{ paddingRight: '15px' }}>
                <ul style={{ listStyleType: 'none', paddingInlineStart: '0px' }}>
                  <li>
                    <Link to="" className="link-text" aria-current="page">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/collections" className="link-text" aria-current="page">
                      Collections
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us" className="link-text" aria-current="page">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/shipping-and-returns" className="link-text" aria-current="page">
                      Shipping & Returns
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact-us" className="link-text" aria-current="page">
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/cart" className="link-text" aria-current="page">
                      <i className="fa fa-shopping-cart" aria-hidden="true" style={{ position: 'relative', fontSize: '24px' }}>
                        {cart?.length > 0 && <div style={{ position: 'absolute', backgroundColor: '#FFB6C1', padding: '2px', paddingLeft: '5px', paddingRight: '5px', borderRadius: '10px', fontSize: '14px', top: '10px', right: '-8px', color: '#ffffff' }}>{cart?.length}</div>}
                      </i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-6 text-left" style={{ paddingLeft: '15px' }}>
                <div className="link-text" aria-current="page" style={{cursor: 'pointer'}} onClick={() => window.location.href = 'https://www.facebook.com/elysiancreativedesign'}>
                <i className="fa fa-facebook-square" aria-hidden="true" style={{ position: 'relative', fontSize: '24px' }}></i>
                </div>
              </div>
              <div className="col-12">
                <p className='note-text'>@2022, developed by Web Applications</p>
              </div>
            </div>
          </div>
        </div>)}
    </UserContext.Consumer>
  );
}

export default Footer;
