import { Link } from 'react-router-dom';
import './Card.scss'

function Card({ data, preroute, type }) {
    return (
        <Link to={`${preroute}/${data?.id}`}>
            <div className="card" style={{border: 'none'}}>
                {type === 'product' && data?.images?.length > 0 &&
                    <img src={`${data?.images[0]?.src}`} className="card-img-top" alt={data?.images[0]?.name} />
                }
                {type === 'category'  &&
                    <img src={`${data?.image?.src}`} className="card-img-top" alt={data?.image?.name} />
                }
                <div className="card-body">
                    <p className="card-name" style={{marginBottom: type === 'category' && '0px'}}>{data?.name}</p>
                    {type === 'product' && <p className="card-price">From R {parseFloat(data?.price)?.toFixed(2)} ZAR</p>}
                    {type === 'product' && <Link to={`${preroute}/${data?.id}`} className="btn add-to-cart-btn">
                        View
                    </Link>}
                </div>
            </div>
        </Link>
    );
}

export default Card;
