import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import ItemView from "../components/item-view/ItemView";
import CollectionService from "../services/CollectionService";
import Loader from "../components/loader/Loader";

function ItemPage() {
    const params = useParams();
    const service = new CollectionService();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        params.itemId && getData(params.itemId);
        return (() => {
            setData();
        })
        // eslint-disable-next-line
    }, [params.itemId])

    const getData = async (id) => {
        const response = await service.getProductById(id);
        setData(response);
        setLoading(false);
    }

    return (
        <div className="page item-page">
            <Loader loading={loading} />
            {data && <ItemView data={data} />}
            <div className="divider"></div>
        </div>
    );
}

export default ItemPage;
