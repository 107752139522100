import { Fragment, useState } from "react";
import { useEffect, useContext } from "react";
import { UserContext } from '../../context/UserContect';
import { Link } from "react-router-dom";
import BabyGrowIcon from '../../images/baby-grow-icon.png';
import SendMailService from "../../services/SendMailService";

import './CartList.scss'

function CartList() {
    const userContext = useContext(UserContext);
    const [cart, setCart] = useState([])
    const [total, setTotal] = useState(0);
    const [placeOrder, setPlaceOrder] = useState(false);
    const [orderPlaced, setOrderPlaced] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [message, setMessage] = useState("");
    const [images, setImages] = useState([]);
    const [imagesSize, setImagesSize] = useState(0);

    useEffect(() => {
        setCart(userContext?.cart);
        let sum = 0;
        userContext?.cart?.forEach((item) => {
            sum = sum + (item?.price * item?.quantity);
        });
        setTotal(sum);
        return () => {
            setCart([]);
            setTotal(0);
            setImages([]);
            setImagesSize(0);
            setOrderPlaced(true);
            setName("");
            setEmail("");
            setNumber("");
            setMessage("");
            setImages([]);
            setImagesSize(0);
        }
    }, [userContext?.cart])

    const order = async (e) => {
        e.preventDefault();
        if (imagesSize >= 5) {
            return;
        }
        const service = new SendMailService();
        const response = await service.sendOrderMail(name, email, number, message, cart, images);
        if (response) {
            userContext.updateCart([]);
            setOrderPlaced(true);
            setName("");
            setEmail("");
            setNumber("");
            setMessage("");
            setImages([]);
            setImagesSize(0);
        }
    }

    const imgFilehandler = async (e) => {
        if (e.target.files.length !== 0) {
            let s = 0
            for (let i = 0; i < e.target.files.length; i++) {
                s = (s+(parseFloat(e.target.files[i].size) / 1048576));
            }
            setImages(e.target.files);
            setImagesSize(s)
        } else {
            setImages([]);
            setImagesSize(0);
        }
    }

    return (
        <div className="cart-list">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2><i className="fa fa-shopping-cart" aria-hidden="true" style={{ position: 'relative', fontSize: '24px' }}>{cart?.length > 0 && <div style={{ position: 'absolute', backgroundColor: '#FFB6C1', padding: '2px', paddingLeft: '5px', paddingRight: '5px', borderRadius: '10px', fontSize: '14px', top: '10px', right: '-8px', color: '#ffffff' }}>{cart?.length}</div>}</i> Your Cart {cart?.length <= 0 && 'is Empty'}</h2>
                        <img src={BabyGrowIcon} alt="icon" style={{ maxHeight: '50px', rotate: '0deg', marginBottom: '20px' }} />
                    </div>
                    <div className="col-0 col-sm-3"></div>
                    <div className="col-12 col-sm-6">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </div>
                    <div>
                        <Link to={`/collections`} className="btn add-to-cart-btn" style={{/*float: 'right', */marginBottom: '10px' }}>
                            Continue Shopping
                        </Link>
                    </div>
                    {cart?.length > 0 && !placeOrder && <Fragment>
                        <div className="row">
                            <div className="col-12">
                                <div className="row" style={{ fontSize: '14px', fontWeight: '400', borderBottom: '0.5px solid', paddingBottom: '20px', marginBottom: '20px' }}>
                                    <div className="col-12 col-sm-6 text-left">
                                        Products
                                    </div>
                                    <div className="col-12 col-sm-3 display-mobile-none">
                                        Quantity
                                    </div>
                                    <div className="col-12 col-sm-3 text-right display-mobile-none">
                                        Total
                                    </div>
                                </div>
                            </div>
                            {cart?.map((item, index) => {
                                return <div className="col-12" key={index}>
                                    <div className="row">
                                        <div className="col-12 col-sm-1" style={{ paddingRight: '10px', paddingBottom: '10px' }}>
                                            <img src={`${item?.imageurl}`} className="cart-image" alt={item?.name} />
                                        </div>
                                        <div className="col-12 col-sm-5 text-left">
                                            <p className="cart-name">{item?.name}</p>
                                            <p className="cart-price">R {parseFloat(item?.price)?.toFixed(2)}</p>
                                            <div>
                                                {item?.size &&
                                                    <div className="select-chip selected">
                                                        {item?.size}
                                                    </div>
                                                }
                                                {item?.color &&
                                                    <div className="select-chip selected">
                                                        {item?.color}
                                                    </div>
                                                }
                                                {item?.style &&
                                                    <div className="select-chip selected">
                                                        {item?.style}
                                                    </div>
                                                }
                                                {item?.option &&
                                                    <div className="select-chip selected">
                                                        {item?.option}
                                                    </div>
                                                }
                                                {item?.print &&
                                                    <div className="select-chip selected">
                                                        {item?.print}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-3">
                                            <div style={{ cursor: 'pointer', paddingBottom: '20px' }}>
                                                <div style={{ display: 'inline-flex', borderTop: '1px solid', borderBottom: '1px solid', padding: '5px 15px 5px', borderLeft: '1px solid', userSelect: 'none' }} onClick={() => {
                                                    let tmp = Object.assign([], cart);
                                                    if (tmp[index]?.quantity > 1) {
                                                        tmp[index].quantity = tmp[index].quantity - 1;
                                                        userContext?.updateCart(tmp);
                                                    }
                                                }}>-</div>
                                                <div style={{ display: 'inline-flex', borderTop: '1px solid', borderBottom: '1px solid', padding: '5px 15px 5px', userSelect: 'none', minWidth: '43.78px', fontWeight: '400' }}>{item?.quantity}</div>
                                                <div style={{ display: 'inline-flex', borderTop: '1px solid', borderBottom: '1px solid', padding: '5px 15px 5px', borderRight: '1px solid', userSelect: 'none' }} onClick={() => {
                                                    let tmp = Object.assign([], cart);
                                                    if (tmp[index]?.quantity < 10) {
                                                        tmp[index].quantity = tmp[index].quantity + 1;
                                                        userContext?.updateCart(tmp);
                                                    }
                                                }}>+</div>
                                                <div style={{ display: 'inline-flex', padding: '5px 15px 5px', userSelect: 'none' }}><i className="fa fa-trash" aria-hidden="true" onClick={() => {
                                                    let tmp = Object.assign([], cart);
                                                    tmp.splice(index, 1);
                                                    userContext?.updateCart(tmp);
                                                }}></i></div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-3 text-right">
                                            <p style={{ fontWeight: '400' }}>R {(item?.price * item?.quantity)?.toFixed(2)}</p>
                                        </div>
                                    </div>
                                </div>
                            })}
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 mt-4">
                                        {imagesSize < 5 ?
                                           <label>Upload Images{imagesSize > 0 ? ` ${imagesSize.toFixed(1)} MB` : ''}</label>
                                           :
                                           <label style={{color: 'red'}}>Upload Images (*total size sould be less than 5 MB)</label>
                                        }
                                        <div className="mb-3">
                                            <input type="file" name="img" className="form-control" id="floatingInputImages" accept=".jpg, .jpeg, .png" multiple onChange={(e) => imgFilehandler(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ borderTop: '0.5px solid', paddingTop: '20px', marginTop: '20px' }}>
                                    <div className="col-12 col-sm-6"></div>
                                    <div className="col-12 col-sm-6 text-right" style={{ fontSize: '14px', fontWeight: '200' }}>
                                        * Shipping @ R 100.00<br />
                                        * Shipping @ R 200.00
                                    </div>
                                    <div className="col-12 col-sm-6"></div>
                                    <div className="col-12 col-sm-6 text-right" style={{ fontSize: '18px', fontWeight: '400', marginBottom: '20px' }}>
                                        Total R {(total).toFixed(2)}
                                    </div>
                                    <div className="col-12 col-sm-6"></div>
                                    <div className="col-12 col-sm-6 text-right" style={{ fontSize: '18px', fontWeight: '400' }}>
                                        <div className="btn add-to-cart-btn" style={{/*float: 'right', */marginBottom: '10px' }} onClick={() => setPlaceOrder(true)}>
                                            Place Order
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                    }
                    {
                        placeOrder && !orderPlaced && <Fragment>
                            <div className='centerdiv' style={{ maxWidth: '600px' }}>
                                <p>Message about order...</p>
                                <form onSubmit={(e) => order(e)}>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="floatingInputName" placeholder="name & surname" value={name} onChange={(e) => setName(e.target.value)} required />
                                        <label for="floatingInputName">Name & Surname</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="email" className="form-control" id="floatingInputEmail" placeholder="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                        <label for="floatingInputEmail">Email</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="tel" className="form-control" id="floatingInputNumber" minLength={10} maxLength={10} placeholder="email" value={number} onChange={(e) => setNumber(e.target.value)} required />
                                        <label for="floatingInputNumber">Mobile Number</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <textarea className="form-control" id="floatingInputMessage" maxLength={2500} style={{ minHeight: '150px' }} value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                                        <label for="floatingInputMessage">Message</label>
                                    </div>
                                    <div className="btn add-to-cart-btn" onClick={() => setPlaceOrder(false)} style={{ marginRight: '10px' }}>
                                        Cancel
                                    </div>
                                    <input className="btn add-to-cart-btn" type="submit" value={"Proceed"} style={{ marginLeft: '10px' }} />
                                </form>
                            </div>
                        </Fragment>
                    }
                    {orderPlaced && <Fragment>
                        <div className='centerdiv' style={{ maxWidth: '600px' }}>
                            <h2>Order Placed</h2>
                            Thank you for ordering from us, you will receive an email about your order...
                        </div>
                    </Fragment>
                    }
                </div>
            </div>
        </div>
    );
}

export default CartList;
