import { useEffect } from "react";
import CartList from "../components/cart-list/CartList";

function CartPage() {

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [])

  return (
    <div className="page cart-page">
      <CartList />
    </div>
  );
}

export default CartPage;
