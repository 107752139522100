import { Routes, Route } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import CollectionsPage from '../pages/CollectionsPage';
import ProductsPage from '../pages/ProductsPage';
import ItemPage from '../pages/ItemPage';
import AboutUsPage from '../pages/AboutUsPage';
import ShippingAndReturnsPage from '../pages/ShippingAndReturnsPage';
import ContactUsPage from '../pages/ContactUsPage';
import CartPage from '../pages/CartPage';
import NotFoundPage from '../pages/NotFoundPage';

function MyRoutes() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<HomePage />} />
        <Route path="collections" element={<CollectionsPage />} />
        <Route path="products">
          <Route path=":categoryId" element={<ProductsPage />} />
        </Route>
        <Route path="item">
          <Route path=":itemId" element={<ItemPage />} />
        </Route>
        <Route path="about-us" element={<AboutUsPage />} />
        <Route path="shipping-and-returns" element={<ShippingAndReturnsPage />} />
        <Route path="contact-us" element={<ContactUsPage />} />
        <Route path="cart" element={<CartPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
}

export default MyRoutes;
