import axios from 'axios';

class CollectionService {
    constructor () {
        this._config = {
            'Content-Type': 'application/json;charset=UTF-8'
        }
        //https://admin.elysiancreativedesigns.co.za/wp-json/wc/v3/products?consumer_key=ck_9095755bc0e8cdc573aa763f93428a91885ea4bd&consumer_secret=cs_37ecf6eb94ebd8ad9a88c496f3303db861f90cf1
        this._api = {
            url: "https://admin.elysiancreativedesigns.co.za",
            index: "/wp-json/wc/v3",
            consumerKey: "ck_9095755bc0e8cdc573aa763f93428a91885ea4bd",
            consumerSecret: "cs_37ecf6eb94ebd8ad9a88c496f3303db861f90cf1",
            version: "wc/v3"
          }
    }

    getCategories = async () => {
        try {
            const response = await axios.get(`${this._api.url}${this._api.index}/products/categories?consumer_key=${this._api.consumerKey}&consumer_secret=${this._api.consumerSecret}`, this._config);
            if (response?.data) {
                return response;
            }
            return {};
        } catch (e) {
            return {}
        }
    }

    getProducts = async () => {
        try {
            const response = await axios.get(`${this._api.url}${this._api.index}/products?consumer_key=${this._api.consumerKey}&consumer_secret=${this._api.consumerSecret}`, this._config);
            if (response?.data) {
                return response;
            }
            return {};
        } catch (e) {
            return {}
        }
    }

    getProductsByCategory = async (category) => {
        try {
            const response = await axios.get(`${this._api.url}${this._api.index}/products?category=${category}&consumer_key=${this._api.consumerKey}&consumer_secret=${this._api.consumerSecret}`, this._config);
            if (response?.data) {
                return response;
            }
            return {};
        } catch (e) {
            return {}
        }
    }

    getProductById = async (id) => {
        try {
            const response = await axios.get(`${this._api.url}${this._api.index}/products/${id}?consumer_key=${this._api.consumerKey}&consumer_secret=${this._api.consumerSecret}`, this._config);
            if (response?.data) {
                return response?.data;
            }
            return {};
        } catch (e) {
            return {}
        }
    }
}

export default CollectionService;