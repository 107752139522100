import { Fragment } from 'react';
import BabyGrowIcon from '../../images/baby-grow-icon.png';
import './Loader.scss'

function Loader({ loading }) {
    return (
        <Fragment>
            {loading &&
                <div className="loader">
                    <img className="baby-grow-image" src={BabyGrowIcon} alt="icon"/>
                    <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
            }
        </Fragment>
    );
}

export default Loader;
