import Card from "./card/Card";
import { Link } from "react-router-dom";
import './GridView.scss'

function GridView({ data, preroute, type }) {
    return (
        <div className="grid-view">
            <div className="container-fluid">
                {data?.length > 0 && <div className="row" style={{ padding: '5px' }}>
                    {data?.map((item) => {
                        if (type === 'category' && item?.count <= 0) {
                            return false;
                        }
                        return <div key={item?.id} className="col-12 col-md-6 col-lg-4 col-xl-3 grid-view-card">
                            <Card data={item} preroute={preroute} type={type} />
                        </div>
                    })}
                </div>}
                {type === 'product' && <div>
                    <Link to={`/collections`} className="btn add-to-cart-btn" style={{/*float: 'right', */marginBottom: '10px' }}>
                        Back to Collections
                    </Link>
                </div>}
            </div>
        </div>
    );
}

export default GridView;
