import './ContactForm.scss'
import SendMailService from '../../services/SendMailService';
import { useState } from 'react';
import { Link } from 'react-router-dom'

function ContactForm() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [messageSend, setMessageSend] = useState(false);

  const sendMessage = async (e) => {
    e.preventDefault();
    const service = new SendMailService();
    const response = await service.sendContactMail(name, email, number, message);
    if (response) {
      setMessageSend(true);
      setName("");
      setEmail("");
      setNumber("");
      setMessage("");
    }
  }

  return (
    <div className="contact-form">
      <div>
        <Link to={`/collections`} className="btn add-to-cart-btn" style={{/*float: 'right', */marginBottom: '10px' }}>
          Continue Shopping
        </Link>
      </div>
      <div className='centerdiv' style={{ maxWidth: '600px' }}>
        {!messageSend && <form onSubmit={(e) => sendMessage(e)}>
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="floatingInputName" placeholder="name & surname" value={name} onChange={(e) => setName(e.target.value)} required />
            <label for="floatingInputName">Name & Surname</label>
          </div>
          <div className="form-floating mb-3">
            <input type="email" className="form-control" id="floatingInputEmail" placeholder="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
            <label for="floatingInputEmail">Email</label>
          </div>
          <div className="form-floating mb-3">
            <input type="tel" className="form-control" id="floatingInputNumber" minLength={10} maxLength={10} placeholder="email" value={number} onChange={(e) => setNumber(e.target.value)} required />
            <label for="floatingInputNumber">Mobile Number</label>
          </div>
          <div className="form-floating mb-3">
            <textarea className="form-control" id="floatingInputMessage" maxLength={2500} style={{ minHeight: '150px' }} value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>
            <label for="floatingInputMessage">Message</label>
          </div>
          <div>
            <input className="btn add-to-cart-btn" type="submit" value={"Send Message"} />
          </div>
        </form>}
        {messageSend && <div>
          <h2>Thank you for contacting us.</h2>
        </div>}
      </div>
    </div>
  );
}

export default ContactForm;
